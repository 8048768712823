<template>
  <div class="container my-24 my-lg-40">
    <div class="row">
      <div class="col">
        <v-sheet
          elevation="4"
          rounded
          class="py-32 px-16 p-xl-32"
        >
          <div class="row">
            <div class="col">
              <p>
                Оцените, какое утверждение Вам ближе. Вы можете выбрать только одно более близкое Вам утверждение из
                двух.
              </p>
            </div>
          </div>

          <div class="row mt-8 mt-xl-16">
            <div class="col">
              <linear-choice
                v-for="variant in variants"
                :key="variant.code"
                :value="variant.answer"
                :options="variant.options"
                class="mb-16"
              >
                <template #textLeft>
                  {{ variant.l }}
                </template>

                <template #default>
                  <v-linear-radio
                    type360
                    v-model="variant.answer"
                    :variants="variant.options"
                  />
                </template>

                <template #textRight>
                  {{ variant.r }}
                </template>
              </linear-choice>
            </div>
          </div>
        </v-sheet>
      </div>
    </div>
  </div>
</template>

<script>
import { VSheet, VLinearRadio } from '@components/base'
import { LinearChoice } from '@components/features'
import { createCustomArray } from '@utils/helpers'

export default {
  name: 'SLinearChoice2',

  components: {
    VSheet,
    LinearChoice,
    VLinearRadio
  },

  data () {
    return {
      variants: createCustomArray({
        length: 100,
        template: i => ({
          id: i,
          l: `Вопрос №${i + 1}.1 Нельзя достичь высоких результатов в работе, если обстоятельства против тебя`,
          r: `Вопрос №${i + 1}.2 Лучше высказать свои претензии руководителю открыто, чем молчать и терпеть`,
          options: [
            { id: '1', text: 'Абсолютно согласен' },
            { id: '2', text: 'Согласен' },
            { id: '3', text: 'Скорее согласен' },
            { id: '4', text: 'Скорее согласен' },
            { id: '5', text: 'Согласен' },
            { id: '6', text: 'Абсолютно согласен' }
          ],
          answer: ''
        })
      })
    }
  }
}
</script>
